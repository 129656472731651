
import { MapConfig } from 'lib-customizations/types'

const mapConfig: MapConfig = {
  center: [64.12923, 29.52311],
  initialZoom: 13,
  addressZoom: 14,
  searchAreaRect: {
    maxLatitude: 64.42923272117555,
    minLatitude: 63.92923272117555,
    maxLongitude: 29.823115289452452,
    minLongitude: 29.323115289452452,
  },
  careTypeFilters: ['DAYCARE', 'PRESCHOOL', 'CLUB'],
  unitProviderTypeFilters: [
    'MUNICIPAL',
    'PURCHASED',
    'PRIVATE',
    'PRIVATE_SERVICE_VOUCHER',
  ],
}

export default mapConfig
