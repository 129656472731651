
import React from 'react'
// import styled from 'styled-components'

// import { FixedSpaceColumn } from 'lib-components/layout/flex-helpers'
import type { CitizenCustomizations } from 'lib-customizations/types'

import { citizenConfig } from './appConfigs'
import MunicipalityLogo from './assets/municipality-logo-primary.svg'
import featureFlags from './featureFlags'
import mapConfig from './mapConfig'
import { H1, H2, P } from 'lib-components/typography'
import ExternalLink from 'lib-components/atoms/ExternalLink'
import colors from 'lib-customizations/common'

// const MultiLineCheckboxLabel = styled(FixedSpaceColumn).attrs({
//   spacing: 'zero'
// })`
//   margin-top: -6px;
// `

const customizations: CitizenCustomizations = {
  appConfig: citizenConfig,
  langs: ['fi', 'sv', 'en'],
  translations: {
    fi: {
      footer: {
        cityLabel: '© Kuhmon kaupunki',
        privacyPolicyLink: (
          <a
            href="https://www.kuhmo.fi/kaupunki-ja-paatoksenteko/asiointi/tietosuoja/"
            data-qa="footer-policy-link"
            style={{ color: colors.main.m2 }}
          >
            Tietosuojaselosteet
          </a>
        ),
        sendFeedbackLink: (
          <a
            href="https://www.kuhmo.fi/kaupunki-ja-paatoksenteko/asiointi/osallistu-ja-vaikuta/anna-palautetta/"
            data-qa="footer-feedback-link"
            style={{ color: colors.main.m2 }}
          >
            Lähetä palautetta
          </a>
        ),
      },
      loginPage: {
        title: 'Kuhmon kaupungin varhaiskasvatus',
        login: {
          infoBoxText: (
            <>
              <P>
                {/* If you are not able to log in here, see the instructions{' '}
                <a
                  href="https://www.espoo.fi/fi/palvelut/evaka"
                  target="_blank"
                  rel="noreferrer"
                >
                  eVaka | Kuhmon kaupunki
                </a>
                . You can also log in with strong authentication. */}
              </P>
            </>
          ),
        },
      },
      map: {
        mainInfo:
          "Tässä näkymässä voit hakea kartalta Kuhmon varhaiskasvatus-, esiopetus- ja kerhopaikkoja.",
      },
      applications: {
        creation: {
          transferApplicationInfo: {
            DAYCARE:
              "Your child already has a place in the City of Kuhmo's early childhood education. Use this application to apply for a transfer to another early childhood education unit.",
          },
        },
        editor: {
          contactInfo: {
            futureAddressInfo:
              "Kuhmon varhaiskasvatuksessa virallisena osoitteena pidetään väestötiedoista saatavaa osoitetta. Osoite väestötiedoissa muuttuu hakijan tehdessä muuttoilmoituksen postiin tai maistraattiin.",
          },
        },
      },
      children: {
        vasu: {
          sharingLeopsDisclaimer:
            "When a child changes to another early childhood education unit in the City of Kuhmo, the early childhood education plan and other early childhood education documents are transferred to the new unit (also applies to early childhood education organized by Kuhmo as a purchasing service). The guardian will be informed about the transfer in advance. We ask the guardian for permission to hand over the early childhood education plan to an outsider. However, if a child enters private or other municipality's early childhood education, the early childhood education plan must be submitted to the new early childhood education provider even without permission, if the information is necessary for arranging the child's early childhood education (section 41 (3) of the Early Childhood Education Act). In addition, the early childhood education plan must be submitted to the organizer of pre-primary or primary education if it is necessary for the organization of the child's education (section 41 (4) of the Basic Education Act). The guardian will be informed of the transfer in advance.",
        },
      },
      accessibilityStatement: (
        <>
          <H1>Saavutettavuusseloste</H1>
          <P>
            Tämä saavutettavuusseloste koskee Kuhmon kaupungin varhaiskasvatuksen
            eVaka-verkkopalvelua osoitteessa{' '}
            <a href="https://varhaiskasvatus.kuhmo.fi">varhaiskasvatus.kuhmo.fi</a>.
            Sastalaman kaupunki pyrkii takaamaan verkkopalvelun saavutettavuuden,
            parantamaan käyttäjäkokemusta jatkuvasti ja soveltamaan asianmukaisia
            saavutettavuusstandardeja.
          </P>
          <P>
            Palvelun saavutettavuuden on arvioinut palvelun kehitystiimi, ja seloste
            on laadittu 12.4.2022.
          </P>
          <H2>Palvelun vaatimustenmukaisuus</H2>
          <P>
            Verkkopalvelu täyttää lain asettamat kriittiset
            saavutettavuusvaatimukset WCAG v2.1 -tason AA mukaisesti. Palvelu ei ole
            vielä kaikilta osin vaatimusten mukainen.
          </P>
          <H2>Toimet saavutettavuuden tukemiseksi</H2>
          <P>
            Verkkopalvelun saavutettavuus varmistetaan muun muassa seuraavilla
            toimenpiteillä:
          </P>
          <ul>
            <li>
              Saavutettavuus huomioidaan alusta lähtien suunnitteluvaiheessa, mm.
              valitsemalla palvelun värit ja kirjaisinten koot saavutettavasti.
            </li>
            <li>
              Palvelun elementit on määritelty semantiikaltaan johdonmukaisesti.
            </li>
            <li>Palvelua testataan jatkuvasti ruudunlukijalla.</li>
            <li>
              Erilaiset käyttäjät testaavat palvelua ja antavat saavutettavuudesta
              palautetta.
            </li>
            <li>
              Sivuston saavutettavuudesta huolehditaan jatkuvalla valvonnalla
              tekniikan tai sisällön muuttuessa.
            </li>
          </ul>
          <P>
            Tätä selostetta päivitetään sivuston muutosten ja saavutettavuuden
            tarkistusten yhteydessä.
          </P>
          <H2>Tunnetut saavutettavuusongelmat</H2>
          <P>
            Käyttäjät saattavat edelleen kohdata sivustolla joitakin ongelmia.
            Seuraavassa on kuvaus tunnetuista saavutettavuusongelmista. Jos huomaat
            sivustolla ongelman, joka ei ole luettelossa, otathan meihin yhteyttä.
          </P>
          <ul>
            <li>
              Palvelun päivämäärävalitsinta ja monivalintojen alasvetovalikkoa ei
              ole optimoitu käytettäväksi ruudunlukijalla.
            </li>
            <li>
              Palvelun yksikkökartassa ei pysty liikkumaan
              näppäimistöllä/ruudunlukijalla, mutta yksikköjä voi selata samassa
              näkymässä olevalta listalta. Palvelussa käytetty kartta on kolmannen
              osapuolen tuottama.
            </li>
          </ul>
          <H2>Kolmannet osapuolet</H2>
          <P>
            Verkkopalvelussa käytetään seuraavia kolmannen osapuolen palveluita,
            joiden saavutettavuudesta emme voi vastata.
          </P>
          <ul>
            <li>Keycloak käyttäjän tunnistautumispalvelu</li>
            <li>Suomi.fi-tunnistautuminen</li>
            <li>Leaflet-karttapalvelu</li>
          </ul>
          <H2>Anna palautetta</H2>
          <P>
            Jos huomaat saavutettavuuspuutteen verkkopalvelussamme, kerro siitä
            meille. Voit antaa palautetta{' '}
            <ExternalLink
              href="https://www.kuhmo.fi/kaupunki-ja-paatoksenteko/asiointi/osallistu-ja-vaikuta/anna-palautetta/"
              text="verkkolomakkeella"
            />{' '}
            tai sähköpostitse{' '}
            <a href="mailto:kirjaamo@kuhmo.fi">kirjaamo@kuhmo.fi</a>.
          </P>
          <H2>Valvontaviranomainen</H2>
          <P>
            Jos huomaat sivustolla saavutettavuusongelmia, anna ensin palautetta
            meille sivuston ylläpitäjille. Vastauksessa voi mennä 14 päivää. Jos et
            ole tyytyväinen saamaasi vastaukseen, tai et saa vastausta lainkaan
            kahden viikon aikana, voit antaa palautteen Etelä-Suomen
            aluehallintovirastoon. Etelä-Suomen aluehallintoviraston sivulla
            kerrotaan tarkasti, miten valituksen voi tehdä, ja miten asia
            käsitellään.
          </P>
          <P>
            <strong>Valvontaviranomaisen yhteystiedot </strong>
            <br />
            Etelä-Suomen aluehallintovirasto <br />
            Saavutettavuuden valvonnan yksikkö
            <br />
            <ExternalLink
              href="https://www.saavutettavuusvaatimukset.fi"
              text="www.saavutettavuusvaatimukset.fi"
            />
            <br />
            <a href="mailto:saavutettavuus@avi.fi">saavutettavuus@avi.fi</a>
            <br />
            puhelinnumero vaihde 0295 016 000
            <br />
            Avoinna: ma-pe klo 8.00–16.15
          </P>
        </>
      ),
    },
    sv: {},
    en: {
      footer: {
        cityLabel: '© City of Kuhmo',
        privacyPolicyLink: (
          <a
            href="https://www.kuhmo.fi/kaupunki-ja-paatoksenteko/asiointi/tietosuoja/"
            data-qa="footer-policy-link"
            style={{ color: colors.main.m2 }}
          >
            Privacy notices
          </a>
        ),
        sendFeedbackLink: (
          <a
            href="https://www.kuhmo.fi/kaupunki-ja-paatoksenteko/asiointi/osallistu-ja-vaikuta/anna-palautetta/"
            data-qa="footer-feedback-link"
            style={{ color: colors.main.m2 }}
          >
            Give feedback
          </a>
        ),
      },
      loginPage: {
        title: 'City of Kuhmo early childhood education',
        login: {
          infoBoxText: (
            <>
              <P>
                {/* If you are not able to log in here, see the instructions{' '}
                <a
                  href="https://www.espoo.fi/fi/palvelut/evaka"
                  target="_blank"
                  rel="noreferrer"
                >
                  eVaka | Kuhmon kaupunki
                </a>
                . You can also log in with strong authentication. */}
              </P>
            </>
          ),
        },
      },
      map: {
        mainInfo:
          "In this view, you can search the map for Kuhmo's early childhood education, pre-primary education and club places.",
      },
      applications: {
        creation: {
          transferApplicationInfo: {
            DAYCARE:
              "Your child already has a place in the City of Kuhmo's early childhood education. Use this application to apply for a transfer to another early childhood education unit.",
          },
        },
        editor: {
          contactInfo: {
            futureAddressInfo:
              "Kuhmo's early childhood education considers the address retrieved from the Population Register Centre as the official address. The address in the Population Register Centre changes when the applicant makes a notification of move to Posti or the Local Register Office.",
          },
        },
      },
      children: {
        vasu: {
          sharingLeopsDisclaimer:
            "When a child changes to another early childhood education unit in the City of Kuhmo, the early childhood education plan and other early childhood education documents are transferred to the new unit (also applies to early childhood education organized by Kuhmo as a purchasing service). The guardian will be informed about the transfer in advance. We ask the guardian for permission to hand over the early childhood education plan to an outsider. However, if a child enters private or other municipality's early childhood education, the early childhood education plan must be submitted to the new early childhood education provider even without permission, if the information is necessary for arranging the child's early childhood education (section 41 (3) of the Early Childhood Education Act). In addition, the early childhood education plan must be submitted to the organizer of pre-primary or primary education if it is necessary for the organization of the child's education (section 41 (4) of the Basic Education Act). The guardian will be informed of the transfer in advance.",
        },
      },
      accessibilityStatement: (
        <>
          <H1>Accessibility statement</H1>
          <P>
            This accessibility statement applies to the City of Kuhmo's early
            childhood education online service eVaka at{' '}
            <a href="https://varhaiskasvatus.kuhmo.fi">varhaiskasvatus.kuhmo.fi</a>.
            The City of Kuhmo endeavours to ensure the accessibility of the online
            service, continuously improve the user experience and apply appropriate
            accessibility standards.
          </P>
          <P>
            The accessibility of the service was assessed by the development team of
            the service, and this statement was drawn up on 12 April 2022.
          </P>
          <H2>Compliance of the service</H2>
          <P>
            The online service complies with the statutory critical accessibility
            requirements in accordance with Level AA of the Accessibility Guidelines
            for the WCAG v2.1. The service is not yet fully compliant with the
            requirements.
          </P>
          <H2>Measures to support accessibility</H2>
          <P>
            The accessibility of the online service is ensured, among other things,
            by the following measures:
          </P>
          <ul>
            <li>
              Accessibility has been taken into account from the beginning of the
              design phase, for example, when choosing the colours and font sizes of
              the service.
            </li>
            <li>
              The service elements have been defined in consistently in terms of
              semantics.
            </li>
            <li>The service is continuously tested with a screen reader.</li>
            <li>
              Various users test the service and give feedback on its accessibility.
            </li>
            <li>
              When website technology or content changes, its accessibility is
              ensured through constant monitoring.
            </li>
          </ul>
          <P>
            This statement will be updated in conjunction with website changes and
            accessibility evaluations.
          </P>
          <H2>Known accessibility issues</H2>
          <P>
            Users may still encounter some issues on the website. The following
            contains a description of known accessibility issues. If you notice an
            issue on the site that is not listed, please contact us.
          </P>
          <ul>
            <li>
              Date pickers and multi-select dropdowns in the service are not
              optimised to be used with a screen reader.
            </li>
            <li>
              The service's unit map cannot be navigated using the keyboard/screen
              reader, but the units can be browsed on the list available in the same
              view. The map used in the service is produced by a third party.
            </li>
          </ul>
          <H2>Third parties</H2>
          <P>
            The online service uses the following third party services, the
            accessibility of which we cannot be responsible for.
          </P>
          <ul>
            <li>Keycloak user identification service</li>
            <li>Suomi.fi identification</li>
            <li>Leaflet map service</li>
          </ul>
          <H2>Give feedback</H2>
          <P>
            If you notice an accessibility gap in our online service, please let us
            know! You can give us feedback using the{' '}
            <ExternalLink
              href="https://www.kuhmo.fi/kaupunki-ja-paatoksenteko/asiointi/osallistu-ja-vaikuta/anna-palautetta/"
              text="online form"
            />{' '}
            or by emailing{' '}
            <a href="mailto:kirjaamo@kuhmo.fi">kirjaamo@kuhmo.fi</a>.
          </P>
          <H2>Supervisory authority</H2>
          <P>
            If you notice any accessibility issues on the website, please send us,
            the site administrator, feedback first. It may take us up to 14 days to
            reply. If you are not satisfied with the reply or you do not receive a
            reply within two weeks, you can give feedback to the Regional State
            Administrative Agency for Southern Finland. The website of the Regional
            State Administrative Agency for Southern Finland explains in detail how
            a complaint can be submitted, and how the matter will be processed.
          </P>
          <P>
            <strong>Contact information of the supervisory authority</strong>
            <br />
            Regional State Administrative Agency of Southern Finland
            <br />
            Accessibility Supervision Unit
            <br />
            <ExternalLink
              href="https://www.saavutettavuusvaatimukset.fi"
              text="www.saavutettavuusvaatimukset.fi"
            />
            <br />
            <a href="mailto:saavutettavuus@avi.fi">saavutettavuus@avi.fi</a>
            <br />
            tel. (exchange) 0295 016 000
            <br />
            Open: Mon-Fri 8.00–16.15
          </P>
        </>
      ),
    },
  },
  cityLogo: {
    src: MunicipalityLogo,
    alt: 'Kuhmo logo',
  },
  routeLinkRootUrl: 'https://reittiopas.hsl.fi/reitti/',
  mapConfig,
  featureFlags,
  getMaxPreferredUnits() {
    return 3
  },
}

export default customizations
